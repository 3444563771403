<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <b-overlay :show="is_load">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">

                            <b-table :fields="colunas" :items="dados" class="table table-striped">
                                <template v-slot:cell(name)="data">
                                    <a class="btn btn-sm btn-success" :href="url_vindi+'admin/plans/'+data.item.id" target="_blank"><i class="dripicons-link"></i> vindi</a> {{ data.item.name }}
                                </template>
                                <template v-slot:cell(produtos)="data">

                                   <span v-if="data.item.plan_items">
                                        <span v-for="(item,index) in data.item.plan_items" :key="item.id">
                                           <span v-if="index>0">, </span> <a class="btn btn-sm btn-success" :href="url_vindi+'admin/products/'+item.product.id" target="_blank"><i class="dripicons-link"></i> vindi</a>  {{ item.product.name }}
                                        </span>
                                   </span>
                                </template>
                                <template v-slot:cell(contador)="data">
                                    {{ data.index + 1 }}
                                </template>
                            </b-table>

                        </div>
                    </div>
                </div>
            </div>
        </b-overlay>

    </Layout>
</template>

<script>

import Layout from "../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
export default {
    name: "planos",
    components: {
        Layout,
        PageHeader
    },
    data() {
        return {
            url_vindi: process.env.VUE_APP_DEFAULT_VINDI || 'https://sandbox-app.vindi.com.br/',
            is_load: false,
            dados: [],
            colunas: [
                {key: 'contador', label: ''},
                {key: 'id', label: 'ID'},
                {key: 'name', label: 'Plano'},
                {key: 'code', label: 'Código'},
                {key: 'status', label: 'Status'},
                {key: 'interval_name', label: 'Tipo'},
                {key: 'produtos', label: 'Produto(s)'},
            ],
            title: "Planos",
            items: [
                {
                    text: "Home"
                },
                {
                    text: "Planos",
                    active: true
                }
            ]
        };
    },
    mounted() {
        this.getPlanos()


    },
    methods: {
        getPlanos() {
            this.is_load = true;
            axios.get('planos').then(response => {
                this.dados = response.data;
                this.is_load = false;
                console.log(this.dados)
            }).catch(error => {
                this.is_load = false;
                this.dados = [];
                console.log(error)
            })
        }
    }
}
</script>

<style>

</style>